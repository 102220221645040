<template>
  <section class="text-gray-700 body-font relative">
    <div class="absolute inset-0 bg-gray-300">
      <iframe
        width="100%"
        height="100%"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="map"
        scrolling="no"
        src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=%C4%B0zmir+av%20ana%20costa%2061&ie=UTF8&t=&z=14&iwloc=B&output=embed"
        style="filter: grayscale(0.3) contrast(1.2) opacity(0.4)"
      ></iframe>
    </div>
    <div class="container px-5 py-24 mx-auto flex">
      <div
        class="lg:w-1/2 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10"
      >
        <h2
          class="text-lg leading-9 font-extrabold tracking-tight text-gray-90"
        >
          Deseja trabalhar conosco?
        </h2>
        <p class="my-2 text-sm leading-6 text-gray-500">
          Fale um pouco mais sobre você e retornaremos em breve.
        </p>
        <form
          @submit.stop.prevent="onSubmit"
          method="POST"
          data-cy="login-form"
          class="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
        >
          <div>
            <label
              for="first_name"
              class="block text-xs font-medium leading-5 text-gray-700"
              >Nome</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                required
                v-model="form.first_name"
                id="first_name"
                class="form-input py-1 px-3 block w-full transition ease-in-out duration-150"
              />
            </div>
          </div>
          <div>
            <label
              for="last_name"
              class="block text-xs font-medium leading-5 text-gray-700"
              >Sobrenome</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                required
                v-model="form.last_name"
                id="last_name"
                class="form-input py-1 px-3 block w-full transition ease-in-out duration-150"
              />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label
              for="email"
              class="block text-xs font-medium leading-5 text-gray-700"
              >Email</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                required
                v-model="form.email"
                id="email"
                type="email"
                class="form-input py-1 px-3 block w-full transition ease-in-out duration-150"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label
              for="company"
              class="block text-xs font-medium leading-5 text-gray-700"
              >Assunto</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                required
                v-model="form.company"
                id="company"
                class="form-input py-1 px-3 block w-full transition ease-in-out duration-150"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label
              for="phone_number"
              class="block text-xs font-medium leading-5 text-gray-700"
              >Número de telefone</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                required
                v-model="form.tel"
                id="phone_number"
                class="form-input py-1 px-3 block w-full transition ease-in-out duration-150"
                placeholder="(13) 99999-9999"
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label
              for="message"
              class="block text-xs font-medium leading-5 text-gray-700"
              >Mensagem</label
            >
            <div class="mt-1 relative rounded-md shadow-sm">
              <textarea
                v-model="form.message"
                id="message"
                rows="4"
                class="form-textarea py-1 px-3 block w-full transition ease-in-out duration-150"
              ></textarea>
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <input
                  required
                  class="checkbox"
                  type="checkbox"
                  value="termos"
                  required
                />
              </div>
              <div class="ml-3">
                <p class="text-base leading-6 text-gray-500">
                  Ao selecionar isso, você concorda com os
                  <a href="/termos" class="font-medium text-gray-700 underline"
                    >Termos de Uso</a
                  >
                  e nossa
                  <a
                    href="/politica"
                    class="font-medium text-gray-700 underline"
                    >Política de Privacidade</a
                  >.
                </p>
              </div>
            </div>
          </div>
          <div class="sm:col-span-2">
            <span class="w-full inline-flex rounded-md shadow-sm">
              <button
                type="submit"
                :class="{
                  'cursor-not-allowed': loading,
                  'cursor-not-allowed': alreadySent,
                }"
                :disabled="alreadySent"
                class="disabled w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-co-500 hover:bg-gray-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
              >
                <svg
                  v-if="loading"
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Vamos conversar
              </button>
            </span>
            <p class="text-xs text-yellow-600 mt-3" v-if="alreadySent">
              Seu e-mail foi enviado com sucesso, vamos entrar em contato assim
              que possível
            </p>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, maxLength, sameAs } from 'vuelidate/lib/validators';
import router from '@/router';

export default {
  name: 'ContactPage',
  props: {
    authKey: String,
  },
  data() {
    return {
      home: '',
      form: {
        first_name: '',
        last_name: '',
        tel: '',
        message: '',
        company: '',
        email: '',
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  mounted() {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    if (this.isLoggedIn) {
      // Already logged in
      this.logout({ router, slient: true });
    }
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('contact', ['loading', 'alreadySent']),
  },
  methods: {
    ...mapActions('contact', ['sendEmail']),
    onSubmit() {
      // Form submit logic
      this.sendEmail({
        name: this.form.first_name + ' ' + this.form.last_name,
        email: this.form.email,
        message: this.form.message,
        tel: this.form.tel,
        company: this.form.company,
        referer: this.home,
        router,
      });
    },
  },
  metaInfo: {
    title: 'Trabalhe Conosco',
    meta: [
      {
        name: 'description',
        content: 'Entre em contato para parcerias ou vagas.',
      },
    ],
  },
};
</script>

<style scoped></style>
