<template>
  <div class="relative overflow-hidden">
    <section class="relative">
      <section class="text-gray-700 bg-orange-200">
        <div
          class="container mx-auto flex px-5 pt-48 pb-20 md:flex-row flex-col"
        >
          <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
          >
            <h1
              class="mt-4 md:max-w-3xl text-left text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-3xl xl:text-4xl"
            >
              Documentos de compliance para o seu SaaS ou sistema web
            </h1>

            <p class="leading-2 text-gray-600 my-4 text-sm">
              Não sabe se precisa dos documentos?
              <router-link
                to="/artigos/o-que-e-lgpd-e-por-que-sua-empresa-deve-se-adequar"
                class="text-sm underline"
                >Ler sobre a importância de estar de acordo com a
                LGPD</router-link
              >
            </p>
            <FeaturesOnPolicyPage text="seu SaaS" />

            <div class="">
              <a
                href="/app"
                class="btn bg-et-500 hover:scale-105 duration-150 transition hover:bg-et-600 mt-6 text-white rounded-full mr-2 btn-md tracking-tighter px-6 py-3 mx-auto leading-2"
              >
                Gerar minha política de privacidade sem custos
              </a>
              <router-link
                :to="$route.path + '/exemplo'"
                class="btn hover:scale-105 bg-gray-200 duration-150 transition hover:bg-gray-300 mt-6 text-gray-800 rounded-full mr-2 btn-md tracking-tighter px-6 py-3 mx-auto leading-2"
              >
                Ver exemplo
              </router-link>
            </div>
          </div>
          <div
            class="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left md:mb-0 items-center text-center"
          >
            <lottie-animation
              path="img/drawkit-grape-animation-1-LOOP.json"
              class="hidden md:block absolute bottom-2"
              :speed="1"
              style="height: 600px; width: 700px"
            />
          </div>
        </div>
      </section>
    </section>
    <ForAllCompanies />

    <Features></Features>

    <FAQ></FAQ>
  </div>
</template>

<script>
import Features from '@/components/Home/HomeET/Features';
import CTA from '@/components/Home/HomeET/CTA';
import FeaturesOnPolicyPage from '@/views/Politicas/FeaturesOnPolicyPage';
import Btn from '@/components/Home/HomeET/Btn';
import ForAllCompanies from '@/views/ForAllCompanies';
import FAQ from '@/components/Home/HomeET/FAQ';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'; // import lottie-vuejs

export default {
  name: 'PoliticasEcommerce',
  components: {
    FAQ,
    ForAllCompanies,
    Btn,
    FeaturesOnPolicyPage,
    Features,
    LottieAnimation,

    CTA,
  },
  data() {
    return {
      pagina: [],
      content: {},
    };
  },
  created() {},
  computed: {},
  methods: {},

  metaInfo() {
    let title = 'Gerador de termos de uso para seu SaaS';
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      title: title,
      meta: [
        {
          name: 'description',
        },
      ],
    };
  },
};
</script>

<style scoped>
.prose {
  max-width: none !important;
}
</style>
