<template>
  <div v-if="home === 'complianceOnline'">
    <section class="container mx-auto my-24 px-4 md:px-2">
      <section class="container px-4 pb-12 mx-auto md:px-2">
        <h1 class="mb-8 text-xl font-bold md:text-3xl">Perguntas Frequentes</h1>
        <div class="text-sm">
          <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
            <button
              x-spread="trigger"
              class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
              role="button"
              aria-controls="faq4"
              aria-expanded="false"
            >
              O que a LGPD considera como dados pessoais?

              <svg
                :class="{ 'rotate-45': open }"
                class="flex-none w-4 h-4 ml-4 transition transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <div
              x-spread="collapse"
              x-cloak
              class="pb-5 pr-0 text-gray-700 md:pr-40"
            >
              Para a LGPD, dado pessoal é qualquer tipo de dado, seja em meio
              digital ou físico, que possa individualizar ou levar a
              identificação de alguém. Dado pessoal pode ser nome, dados
              financeiros, escolaridade, entre outros que, separados ou juntos,
              individualizam uma pessoa. Ainda, a LGPD conta com o conceito de
              dados pessoais sensíveis, que são toda informação que revele a
              raça, origem étnica, religião, preferência política/partidária,
              filiação sindical, condição de saúde, orientação sexual, biometria
              ou características genéticas de alguém.
            </div>
          </div>
          <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
            <button
              x-spread="trigger"
              class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
              role="button"
              aria-controls="faq5"
              aria-expanded="false"
            >
              Como a Compliance Online me auxilia na adequação à LGPD?
              <svg
                :class="{ 'rotate-45': open }"
                class="flex-none w-4 h-4 ml-4 transition transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <div
              x-spread="collapse"
              x-cloak
              class="pb-5 pr-0 text-gray-700 md:pr-40"
              id="faq5"
            >
              A Compliance Online gera diversos documentos obrigatórios ou
              considerados boas práticas de mercado para a adequação de sua
              empresa à LGPD como Política de Privacidade, Termos de Uso,
              Política de Cookies, entre outros.
            </div>
          </div>
          <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
            <button
              x-spread="trigger"
              class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
              role="button"
              aria-controls="faq6"
              aria-expanded="false"
            >
              Quem precisa se adequar à LGPD?
              <svg
                :class="{ 'rotate-45': open }"
                class="flex-none w-4 h-4 ml-4 transition transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <div
              x-spread="collapse"
              x-cloak
              class="pb-5 pr-0 text-gray-700 md:pr-40"
              id="faq6"
            >
              Qualquer pessoa, física ou jurídica (pública ou privada) que
              realize tratamento de dados pessoais. Portanto, a LGPD não se
              destina apenas a quem trata dados coletados na internet, mas a
              qualquer operação de tratamento de dados pessoais que tenham sido
              coletados dentro do território brasileiro ou que tenha como
              objetivo oferecer bens ou serviços a pessoas localizadas no
              Brasil, independentemente destes dados pessoais terem sido
              coletados offline ou online, em meios físicos ou digitais.
            </div>
          </div>
          <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
            <button
              x-spread="trigger"
              class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
              role="button"
              aria-controls="faq6"
              aria-expanded="false"
            >
              O que é tratamento de dados pessoais?

              <svg
                :class="{ 'rotate-45': open }"
                class="flex-none w-4 h-4 ml-4 transition transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <div
              x-spread="collapse"
              x-cloak
              class="pb-5 pr-0 text-gray-700 md:pr-40"
              id="faq6"
            >
              Tratamento é qualquer tipo de manipulação realizada com dados
              pessoais, seja fisicamente ou virtualmente - como visualizar
              dados, compartilhá-los, coletá-los, arquivá-los, entre outros.
            </div>
          </div>
          <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
            <button
              x-spread="trigger"
              class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
              role="button"
              aria-controls="faq6"
              aria-expanded="false"
            >
              Você pode explicar cookies de terceiros? O que eles são? Por que
              eles apresentam um problema de privacidade?
              <svg
                :class="{ 'rotate-45': open }"
                class="flex-none w-4 h-4 ml-4 transition transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <div
              x-spread="collapse"
              x-cloak
              class="pb-5 pr-0 text-gray-700 md:pr-40"
              id="faq6"
            >
              Cookies (também chamados de cookie HTTP, cookie da web, cookie do
              navegador) são dados enviados de um site e armazenados em seu
              navegador durante a navegação nesse site. Eles foram projetados
              para ajudar os sites a lembrar ou descobrir certas informações
              sobre o usuário visitante. Basicamente, permitem que proprietários
              de sites e terceiros rastreiem a atividade do usuário na web. Essa
              também é a raiz das principais preocupações com a privacidade. Os
              chamados cookies de rastreamento, ou especialmente seu
              subconjunto, cookies de rastreamento de terceiros são usados para
              compilar registros de longo prazo de históricos de navegação de
              indivíduos. <br />
              Esses cookies permitem um perfil bastante completo do usuário
              rastreando o comportamento em vários sites. Um exemplo é o Google
              AdSense e Analytics, que podem servir publicidade e pesquisas
              personalizadas. A legislação da maioria dos países exige que os
              cookies sejam mencionados em uma política de privacidade.
            </div>
          </div>

          <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
            <button
              x-spread="trigger"
              class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
              role="button"
              aria-controls="faq6"
              aria-expanded="false"
            >
              Preciso de uma política de privacidade para uma página do Facebook
              simples ou para minha organização sem fins lucrativos?
              <svg
                :class="{ 'rotate-45': open }"
                class="flex-none w-4 h-4 ml-4 transition transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <div
              x-spread="collapse"
              x-cloak
              class="pb-5 pr-0 text-gray-700 md:pr-40"
            >
              Não, uma simples página do Facebook não requer uma política de
              privacidade para uma organização sem fins lucrativos. No entanto,
              se você começou a coletar informações sobre seus usuários, o
              Facebook exige que você divulgue. <br />
            </div>
          </div>

          <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
            <button
              x-spread="trigger"
              class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
              role="button"
              aria-controls="faq6"
              aria-expanded="false"
            >
              Meus newsletters por e-mail (usando serviços como MailChimp) devem
              conter uma política de privacidade?
              <svg
                :class="{ 'rotate-45': open }"
                class="flex-none w-4 h-4 ml-4 transition transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <div
              x-spread="collapse"
              x-cloak
              class="pb-5 pr-0 text-gray-700 md:pr-40"
              id="faq6"
            >
              Sim. Newsletters por email devem conter uma referência à sua
              política de privacidade. Não se esqueça que outra parte importante
              é mostrar um link para sua política de privacidade quando o
              usuário se inscrever na mesma, a fim de informar o usuário sobre
              suas práticas de coleta / uso de dados. Além da referência à
              política de privacidade, há uma coisa que você não deve esquecer:
              você precisa seguir a legislação CAN-SPAM. Que exige que você
              forneça itens como um link de cancelamento.
            </div>
          </div>

          <div x-data="collapse()" class="px-4 mb-3 bg-gray-100 rounded">
            <button
              x-spread="trigger"
              class="flex items-center justify-between w-full py-4 pr-1 font-semibold text-left text-gray-800 outline-none hover:text-yellow-900 focus:text-black focus:outline-none"
              role="button"
              aria-controls="faq6"
              aria-expanded="false"
            >
              Se eu tiver um aplicativo móvel, qual é uma boa prática para uma
              política de privacidade?
              <svg
                :class="{ 'rotate-45': open }"
                class="flex-none w-4 h-4 ml-4 transition transform"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                aria-hidden="true"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <div
              x-spread="collapse"
              x-cloak
              class="pb-5 pr-0 text-gray-700 md:pr-40"
              id="faq6"
            >
              Em geral, é considerado uma boa prática criar um link para sua
              política de privacidade no aplicativo e na "página de download" da
              app store. A razão para isso é que os usuários devem ser capazes
              de ver as práticas de coleta de dados antes que elas realmente
              aconteçam.
            </div>
          </div>
        </div>
      </section>

      <div class="text-center">
        <a
          href="/artigos"
          target="_blank"
          class="btn btn-light btn-lg btn-icon"
        >
          <span>Saiba mais com nossos artigos</span>
          <svg viewBox="0 0 20 20" fill="currentColor" class="ml-3">
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
      </div>
    </section>
  </div>
  <div v-else>
    <section class="container mx-auto pb-24 mt-16 px-4 md:px-2">
      <h1 class="font-bold text-xl md:text-3xl text-left md:text-center mb-12">
        Perguntas frequentes
      </h1>
      <div class="flex items-start justify-start mb-12">
        <div>
          <p class="font-semibold mt-0 mb-3 text-gray-900">
            Como a Easytermos me auxilia na adequação à LGPD?
          </p>
          <p class="text-gray-700">
            A Easytermos gera diversos documentos obrigatórios ou considerados
            boas práticas de mercado para a adequação de sua empresa à LGPD como
            Política de Privacidade, Termos de Uso, Política de Cookies, entre
            outros.
          </p>
        </div>
      </div>
      <div class="flex items-start justify-start mb-12">
        <div>
          <p class="font-semibold mt-0 mb-3 text-gray-900">
            Quem precisa se adequar à LGPD?
          </p>
          <p class="text-gray-700">
            Qualquer pessoa, física ou jurídica (pública ou privada) que realize
            tratamento de dados pessoais. Portanto, a LGPD não se destina apenas
            a quem trata dados coletados na internet, mas a qualquer operação de
            tratamento de dados pessoais que tenham sido coletados dentro do
            território brasileiro ou que tenha como objetivo oferecer bens ou
            serviços a pessoas localizadas no Brasil, independentemente destes
            dados pessoais terem sido coletados offline ou online, em meios
            físicos ou digitais.
          </p>
        </div>
      </div>
      <div class="flex items-start justify-start mb-12">
        <div>
          <p class="font-semibold mt-0 mb-3 text-gray-900">
            O que é tratamento de dados pessoais?
          </p>
          <p class="text-gray-700">
            Tratamento é qualquer tipo de manipulação realizada com dados
            pessoais, seja fisicamente ou virtualmente - como visualizar dados,
            compartilhá-los, coletá-los, arquivá-los, entre outros.
          </p>
        </div>
      </div>
      <div class="flex items-start justify-start mb-12">
        <div>
          <p class="font-semibold mt-0 mb-3 text-gray-900">
            O que a LGPD considera como dados pessoais?
          </p>
          <p class="text-gray-700">
            Para a LGPD, dado pessoal é qualquer tipo de dado, seja em meio
            digital ou físico, que possa individualizar ou levar a identificação
            de alguém. Dado pessoal pode ser nome, dados financeiros,
            escolaridade, entre outros que, separados ou juntos, individualizam
            uma pessoa. Ainda, a LGPD conta com o conceito de dados pessoais
            sensíveis, que são toda informação que revele a raça, origem étnica,
            religião, preferência política/partidária, filiação sindical,
            condição de saúde, orientação sexual, biometria ou características
            genéticas de alguém.
          </p>
        </div>
      </div>
      <div class="flex items-start justify-start mb-12">
        <div>
          <p class="font-semibold mt-0 mb-3 text-gray-900">
            Você pode explicar cookies de terceiros? O que eles são? Por que
            eles apresentam um problema de privacidade?
          </p>
          <p class="text-gray-700">
            Cookies (também chamados de cookie HTTP, cookie da web, cookie do
            navegador) são dados enviados de um site e armazenados em seu
            navegador durante a navegação nesse site. Eles foram projetados para
            ajudar os sites a lembrar ou descobrir certas informações sobre o
            usuário visitante. Basicamente, permitem que proprietários de sites
            e terceiros rastreiem a atividade do usuário na web. Essa também é a
            raiz das principais preocupações com a privacidade. Os chamados
            cookies de rastreamento, ou especialmente seu subconjunto, cookies
            de rastreamento de terceiros são usados para compilar registros de
            longo prazo de históricos de navegação de indivíduos. <br />
            Esses cookies permitem um perfil bastante completo do usuário
            rastreando o comportamento em vários sites. Um exemplo é o Google
            AdSense e Analytics, que podem servir publicidade e pesquisas
            personalizadas. A legislação da maioria dos países exige que os
            cookies sejam mencionados em uma política de privacidade.
          </p>
        </div>
      </div>
      <div class="flex items-start justify-start mb-12">
        <div>
          <p class="font-semibold mt-0 mb-3 text-gray-900">
            Se eu tiver um aplicativo móvel, qual é uma boa prática para uma
            política de privacidade?
          </p>
          <p class="text-gray-700">
            Em geral, é considerado uma boa prática criar um link para sua
            política de privacidade no aplicativo e na "página de download" da
            app store. A razão para isso é que os usuários devem ser capazes de
            ver as práticas de coleta de dados antes que elas realmente
            aconteçam.
          </p>
        </div>
      </div>
      <div class="flex items-start justify-start mb-12">
        <div>
          <p class="font-semibold mt-0 mb-3 text-gray-900">
            Meus newsletters por e-mail (usando serviços como MailChimp) devem
            conter uma política de privacidade?
          </p>
          <p class="text-gray-700">
            Sim. Newsletters por email devem conter uma referência à sua
            política de privacidade. Não se esqueça que outra parte importante é
            mostrar um link para sua política de privacidade quando o usuário se
            inscrever na mesma, a fim de informar o usuário sobre suas práticas
            de coleta / uso de dados. Além da referência à política de
            privacidade, há uma coisa que você não deve esquecer: você precisa
            seguir a legislação CAN-SPAM. Que exige que você forneça itens como
            um link de cancelamento.
          </p>
        </div>
      </div>
      <div class="flex items-start justify-start mb-12">
        <div>
          <p class="font-semibold mt-0 mb-3 text-gray-900">
            Preciso de uma política de privacidade para uma página do Facebook
            simples ou para minha organização sem fins lucrativos?
          </p>
          <p class="text-gray-700">
            Não, uma simples página do Facebook não requer uma política de
            privacidade para uma organização sem fins lucrativos. No entanto, se
            você começou a coletar informações sobre seus usuários, o Facebook
            exige que você divulgue. <br />
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, maxLength, sameAs } from 'vuelidate/lib/validators';
import router from '@/router';

export default {
  name: 'ContactPage',
  props: {
    authKey: String,
  },
  data() {
    return {
      home: '',
    };
  },
  validations: {},
  mounted() {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
  computed: {},
  methods: {},
  metaInfo: {
    title: 'Perguntas Frequentes',
    meta: [
      {
        name: 'description',
        content:
          'Tire suas dúvidas com as perguntas mais frequentes sobre LGPD.',
      },
    ],
  },
};
</script>

<style scoped></style>
