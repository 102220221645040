<template>
  <div class="relative overflow-hidden">
    <section class="relative">
      <section class="text-gray-700 bg-red-200">
        <div
          class="container mx-auto flex px-5 pt-48 pb-20 md:flex-row flex-col"
        >
          <div
            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
          >
            <h1
              class="mb-4 mt-4 md:max-w-3xl text-left text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-3xl xl:text-4xl"
            >
              Torne-se um parceiro!
            </h1>
            <h2
              class="mb-4 mt-4 md:max-w-3xl text-left text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-xl xl:text-2xl"
            >
              Escritórios, agências e consultorias podem se tornar revendedores
              ou parceiros da easy :)
            </h2>

            <p class="text-gray-800 mt-3 text-md">
              Expanda sua oferta de serviços fornecendo a seus clientes um
              conjunto abrangente de soluções de software de conformidade.
            </p>
            <p class="text-gray-800 mt-3 text-md">
              O Programa de Parceiros da Easy Termos lhe auxilia a combinar suas
              habilidades jurídicas com o conhecimento de nossas soluções para
              permitir que você conclua a adaptação técnica dos sites e
              aplicativos de seus clientes.
            </p>
            <p class="text-gray-800 mt-3 text-md">
              Como funciona? A <b>Easy Termos</b> oferece um programa de
              parceiros no qual automatizaremos seus documentos, criaremos
              templates whitelabel, colocaremos sua marca, daremos suporte
              prioritário, entre outros.
            </p>
            <p class="text-gray-800 mt-3 text-md">
              Como faço para aderir ao programa de Parceiros da Easy Termos? É
              simples. Basta preencher seus dados em nossa página de contato
              para começar.
            </p>

            <div class="">
              <router-link
                to="/contato"
                class="btn bg-et-500 hover:scale-105 duration-150 transition hover:bg-et-600 mt-6 text-white rounded-full mr-2 btn-md tracking-tighter px-6 py-3 mx-auto leading-2"
              >
                Entrar em contato !
              </router-link>
            </div>
          </div>
          <div
            class="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left md:mb-0 items-center text-center"
          >
            <lottie-animation
              path="img/drawkit-grape-animation-4-LOOP.json"
              class="hidden md:block absolute"
              :speed="1"
              style="height: 500px; width: 500px"
            />
          </div>
        </div>
      </section>
    </section>
    <ForAllCompanies />

    <Features></Features>

    <FAQ></FAQ>
  </div>
</template>

<script>
import Features from '@/components/Home/HomeET/Features';
import CTA from '@/components/Home/HomeET/CTA';
import FeaturesOnPolicyPage from '@/views/Politicas/FeaturesOnPolicyPage';
import Btn from '@/components/Home/HomeET/Btn';
import ForAllCompanies from '@/views/ForAllCompanies';
import FAQ from '@/components/Home/HomeET/FAQ';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'; // import lottie-vuejs

export default {
  name: 'PoliticasEcommerce',
  components: {
    FAQ,
    ForAllCompanies,
    Btn,
    FeaturesOnPolicyPage,
    Features,
    LottieAnimation,

    CTA,
  },
  data() {
    return {
      pagina: [],
      content: {},
    };
  },
  created() {},
  computed: {},
  methods: {},

  metaInfo() {
    let title = 'Gerador de termos de uso para sites';
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      title: title,
      meta: [
        {
          name: 'description',
        },
      ],
    };
  },
};
</script>

<style scoped>
.prose {
  max-width: none !important;
}
</style>
